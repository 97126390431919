<template>
  <WebIframe :web-url="shareUrls.about_us" />
</template>

<script>
import WebIframe from '@/components/WebIframe'
import { mapGetters } from 'vuex'
export default {
  components: { WebIframe },
  computed: {
    ...mapGetters([
      'shareUrls'
    ])
  }
}
</script>

<style>

</style>
